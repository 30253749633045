import React, { useContext } from 'react';
import { Context } from '../../Context';

const Modal = ({ title, modalId, children, className }) => {
  const {
    // modalState,
    setModalState
  } = useContext(Context);

  const modalChangeHandler = (event) => {
    console.log(event.target)
    setModalState(event?.target?.checked);
  };

  return (
    <>
      <input type="checkbox" id={modalId} className="modal-toggle" onChange={modalChangeHandler} />
      <div className="modal !mt-0 !mr-0">
        <div
          className={`modal-box !p-dicom-10 lg:!p-dicom-30 border-2 border-dicom-secondary-100 ${className} relative rounded-dicom-10 space-y-dicom-15`}
        >
          <div className="flex items-center justify-between">
            <h3 className="text-dicom-body1 text-dicom-secondary-100">{title}</h3>
            <label
              htmlFor={modalId}
              className="cursor-pointer !text-dicom-secondary-100 active:scale-95"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.781 6.719C17.8508 6.78867 17.9062 6.87143 17.944 6.96255C17.9818 7.05367 18.0013 7.15135 18.0013 7.25C18.0013 7.34865 17.9818 7.44633 17.944 7.53745C17.9062 7.62857 17.8508 7.71133 17.781 7.781L7.28097 18.281C7.14014 18.4218 6.94913 18.5009 6.74997 18.5009C6.55081 18.5009 6.3598 18.4218 6.21897 18.281C6.07814 18.1402 5.99902 17.9492 5.99902 17.75C5.99902 17.5508 6.07814 17.3598 6.21897 17.219L16.719 6.719C16.7886 6.64915 16.8714 6.59374 16.9625 6.55593C17.0536 6.51812 17.1513 6.49866 17.25 6.49866C17.3486 6.49866 17.4463 6.51812 17.5374 6.55593C17.6285 6.59374 17.7113 6.64915 17.781 6.719Z"
                  fill="currentColor"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.21912 6.719C6.14928 6.78867 6.09386 6.87143 6.05605 6.96255C6.01824 7.05367 5.99878 7.15135 5.99878 7.25C5.99878 7.34865 6.01824 7.44633 6.05605 7.53745C6.09386 7.62857 6.14928 7.71133 6.21912 7.781L16.7191 18.281C16.8599 18.4218 17.051 18.5009 17.2501 18.5009C17.4493 18.5009 17.6403 18.4218 17.7811 18.281C17.9219 18.1402 18.0011 17.9492 18.0011 17.75C18.0011 17.5508 17.9219 17.3598 17.7811 17.219L7.28112 6.719C7.21145 6.64915 7.12869 6.59374 7.03757 6.55593C6.94645 6.51812 6.84877 6.49866 6.75012 6.49866C6.65147 6.49866 6.55379 6.51812 6.46267 6.55593C6.37155 6.59374 6.28879 6.64915 6.21912 6.719Z"
                  fill="currentColor"
                />
              </svg>
            </label>
          </div>
          <div className="h-[2px] w-full bg-[#EFF2FF]"></div>
          <div className="flex !flex-col w-full space-y-dicom-15">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
