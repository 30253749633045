// Breadcrumb.js
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { IconRightChevron } from "../../DesignSystem/svgIcons"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { PaginationMeta, resultType } from "../../../atom/userStudies"
import { GetLocalStorage } from "../../../services/LocalStorage"

const Breadcrumb = ({ title }) => {
  const [breadcrum, setBreadcrum] = useState([])
  const ResultType = useRecoilValue(resultType)

  const setPaginationMeta = useSetRecoilState(PaginationMeta)
  const pathname = useLocation().pathname
  const handleCrumbUpdate = (folderId) => {
    if (breadcrum.length === 0) return
    let crumbs = JSON.parse(sessionStorage.getItem("breadcrumbs"))
    const index = crumbs.findIndex((crumb) => crumb.folderId === folderId)
    if (index !== -1) {
      crumbs = crumbs.slice(0, index + 1)
      sessionStorage.setItem("breadcrumbs", JSON.stringify(crumbs))
    }
  }
  const location = useLocation()
  const resetPagination = () => {
    setPaginationMeta({
      total: 0,
      page: 0,
      pageSize: 20
    })
  }
  const handleIndexRoute = () => {
    console.log()
    let index = GetLocalStorage("IndexRoute") || "my-dicom-wallet"
    // navigate(`/dashboard/${index}`, { replace: true })
    window.location.replace(`/dashboard/${index}`)
    resetPagination()
    sessionStorage.removeItem("breadcrumbs")
  }

  const updateBreadcrumbs = () => {
    let crumbs = sessionStorage.getItem("breadcrumbs")

    if (!pathname.split("/").includes("folders") && !pathname.split("/").includes("folder")) {
      sessionStorage.removeItem("breadcrumbs")
      return setBreadcrum([])
    }
    if (crumbs !== null) crumbs = [...JSON.parse(crumbs)]
    else crumbs = []
    setBreadcrum(crumbs)
  }

  useEffect(() => {
    updateBreadcrumbs()
  }, [])

  const IndexRoute = () => {
    if (location.pathname.split("/")[2] === "folders" || location.pathname.split("/")[2] === "shared") {
      return (GetLocalStorage("IndexRoute") || "my-dicom-wallet").replaceAll("-", " ")
    } else {
      return location.pathname.split("/")[2].replaceAll("-", " ")
    }
  }

  return (
    <div>
      {ResultType === "searchQuery" && <span className='text-sm text-gray-500 italic'>Search resluts in</span>}

      <div className='flex items-center flex-wrap text-xs md:text-base'>
        <span
          className='cursor-pointer text-dicom-background2-100 font-semibold hover:underline capitalize'
          onClick={handleIndexRoute}>
          {/* My Wallet */}
          {IndexRoute() === "my dicom wallet" ? "my DICOMWallet" : IndexRoute()}
        </span>
        {breadcrum.map((crumb, index) => (
          <span key={crumb.folderId} className='flex items-center'>
            <span className='md:mx-2'>
              <IconRightChevron />
            </span>
            <Link
              replace={true}
              onClick={() => {
                resetPagination()
                handleCrumbUpdate(crumb.folderId)
              }}
              to={crumb.folderId !== undefined ? `/dashboard/folders/${crumb.folderId}` : "/dashboard/my-dicom-wallet"}
              className='cursor-pointer text-dicom-background2-100 font-semibold hover:underline'>
              {crumb.folderName}
            </Link>
          </span>
        ))}
        {title ? (
          <span className='flex items-center'>
            <span className='mx-2'>
              <IconRightChevron />
            </span>
            <span className='cursor-pointer text-dicom-background2-100 font-semibold hover:underline'>{title}</span>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default Breadcrumb
