import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-hot-toast"
import { fetchData } from "../../../../services/fetching"
import AnyoneWithTheLink from "./AnyoneWithTheLink"
import OnlyPeopleWithEmailAccess from "./OnlyPeopleWithEmailAccess"
import { GetLocalStorage } from "../../../../services/LocalStorage"
import { IconBlackCopy } from "../../../../components/DesignSystem/svgIcons"
import { AccessDropdown } from "./Assets"

const ShareStudy = ({ studyOrFolder, setStudy, modalId }) => {
  const [peopleWithAccessList, setPeopleWithAccessList] = useState([])

  useEffect(() => {
    setPeopleWithAccessList([])
    if (
      studyOrFolder?.type == "study" &&
      studyOrFolder?.code !== undefined &&
      GetLocalStorage("login_key") !== null &&
      GetLocalStorage("login_user").id === studyOrFolder.userId
    ) {
      getSubscribers()
    } else if (
      studyOrFolder?.type == "folder" &&
      GetLocalStorage("login_key") !== null &&
      GetLocalStorage("login_user").id === studyOrFolder.userId
    ) {
      getFoldersubscribers()
    }
  }, [studyOrFolder])

  useEffect(() => {
    console.log(peopleWithAccessList)
  }, [setPeopleWithAccessList])

  const getFoldersubscribers = async () => {
    try {
      const resp = await fetchData("GET", `/folder/share/subscribers?folderId=${studyOrFolder.id}`)
      let shareUserList = []
      resp.data.subscribers.map((item) => {
        shareUserList.push({
          ...item.user,
          access: item.access,
          subscribersId: item.userId
        })
      })

      setPeopleWithAccessList([
        {
          ...resp.data?.user,
          isOwner: true
        },
        ...shareUserList
      ])
    } catch (err) {
      console.log(err)
    }
  }

  const getSubscribers = () => {
    fetchData("GET", `/study/${studyOrFolder?.code}`).then((res) => {
      let resStudy = res.data.data.study
      let shareUserList = []
      console.log(resStudy)
      resStudy?.subscribers.map((item) => {
        shareUserList.push({
          ...item.user,
          access: item.access,
          subscribersId: item.id
        })
      })
      setPeopleWithAccessList([
        {
          ...resStudy?.user,
          isOwner: true
        },
        ...shareUserList
      ])
    })
  }

  const removeEmailAccess = (userEmail) => {
    if (studyOrFolder.type == "study") {
      fetchData("DELETE", `/study/share?studyCode=${studyOrFolder?.code}&email=${userEmail}`).then((res) => {
        if (res.data.data.study) {
          toast.success(`${userEmail} Successfully removed from the shared list`)
          getSubscribers()
        }
      })
    } else if (studyOrFolder.type == "folder") {
      fetchData("DELETE", `/folder/share/unsubscribe?folderId=${studyOrFolder?.id}&email=${userEmail}`)
        .then((res) => {
          if (res.data) {
            toast.success(`${userEmail} Successfully removed from the shared list`)
            getFoldersubscribers()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const computeHash = (email = "") => {
    // Ensure email is a string
    email = typeof email === "string" ? email : ""

    // Trim the leading and trailing whitespaces
    email = email.trim()

    // Transform to all lowercase characters
    email = email.toLowerCase()

    // Compute the MD5 hash
    const hash = md5(email)

    return hash
  }

  const md5 = (inputString) => {
    var hc = "0123456789abcdef"
    function rh(n) {
      var j,
        s = ""
      for (j = 0; j <= 3; j++) s += hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f)
      return s
    }
    function ad(x, y) {
      var l = (x & 0xffff) + (y & 0xffff)
      var m = (x >> 16) + (y >> 16) + (l >> 16)
      return (m << 16) | (l & 0xffff)
    }
    function rl(n, c) {
      return (n << c) | (n >>> (32 - c))
    }
    function cm(q, a, b, x, s, t) {
      return ad(rl(ad(ad(a, q), ad(x, t)), s), b)
    }
    function ff(a, b, c, d, x, s, t) {
      return cm((b & c) | (~b & d), a, b, x, s, t)
    }
    function gg(a, b, c, d, x, s, t) {
      return cm((b & d) | (c & ~d), a, b, x, s, t)
    }
    function hh(a, b, c, d, x, s, t) {
      return cm(b ^ c ^ d, a, b, x, s, t)
    }
    function ii(a, b, c, d, x, s, t) {
      return cm(c ^ (b | ~d), a, b, x, s, t)
    }
    function sb(x) {
      var i
      var nblk = ((x.length + 8) >> 6) + 1
      var blks = new Array(nblk * 16)
      for (i = 0; i < nblk * 16; i++) blks[i] = 0
      for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8)
      blks[i >> 2] |= 0x80 << ((i % 4) * 8)
      blks[nblk * 16 - 2] = x.length * 8
      return blks
    }
    var i,
      x = sb(inputString),
      a = 1732584193,
      b = -271733879,
      c = -1732584194,
      d = 271733878,
      olda,
      oldb,
      oldc,
      oldd
    for (i = 0; i < x.length; i += 16) {
      olda = a
      oldb = b
      oldc = c
      oldd = d
      a = ff(a, b, c, d, x[i + 0], 7, -680876936)
      d = ff(d, a, b, c, x[i + 1], 12, -389564586)
      c = ff(c, d, a, b, x[i + 2], 17, 606105819)
      b = ff(b, c, d, a, x[i + 3], 22, -1044525330)
      a = ff(a, b, c, d, x[i + 4], 7, -176418897)
      d = ff(d, a, b, c, x[i + 5], 12, 1200080426)
      c = ff(c, d, a, b, x[i + 6], 17, -1473231341)
      b = ff(b, c, d, a, x[i + 7], 22, -45705983)
      a = ff(a, b, c, d, x[i + 8], 7, 1770035416)
      d = ff(d, a, b, c, x[i + 9], 12, -1958414417)
      c = ff(c, d, a, b, x[i + 10], 17, -42063)
      b = ff(b, c, d, a, x[i + 11], 22, -1990404162)
      a = ff(a, b, c, d, x[i + 12], 7, 1804603682)
      d = ff(d, a, b, c, x[i + 13], 12, -40341101)
      c = ff(c, d, a, b, x[i + 14], 17, -1502002290)
      b = ff(b, c, d, a, x[i + 15], 22, 1236535329)
      a = gg(a, b, c, d, x[i + 1], 5, -165796510)
      d = gg(d, a, b, c, x[i + 6], 9, -1069501632)
      c = gg(c, d, a, b, x[i + 11], 14, 643717713)
      b = gg(b, c, d, a, x[i + 0], 20, -373897302)
      a = gg(a, b, c, d, x[i + 5], 5, -701558691)
      d = gg(d, a, b, c, x[i + 10], 9, 38016083)
      c = gg(c, d, a, b, x[i + 15], 14, -660478335)
      b = gg(b, c, d, a, x[i + 4], 20, -405537848)
      a = gg(a, b, c, d, x[i + 9], 5, 568446438)
      d = gg(d, a, b, c, x[i + 14], 9, -1019803690)
      c = gg(c, d, a, b, x[i + 3], 14, -187363961)
      b = gg(b, c, d, a, x[i + 8], 20, 1163531501)
      a = gg(a, b, c, d, x[i + 13], 5, -1444681467)
      d = gg(d, a, b, c, x[i + 2], 9, -51403784)
      c = gg(c, d, a, b, x[i + 7], 14, 1735328473)
      b = gg(b, c, d, a, x[i + 12], 20, -1926607734)
      a = hh(a, b, c, d, x[i + 5], 4, -378558)
      d = hh(d, a, b, c, x[i + 8], 11, -2022574463)
      c = hh(c, d, a, b, x[i + 11], 16, 1839030562)
      b = hh(b, c, d, a, x[i + 14], 23, -35309556)
      a = hh(a, b, c, d, x[i + 1], 4, -1530992060)
      d = hh(d, a, b, c, x[i + 4], 11, 1272893353)
      c = hh(c, d, a, b, x[i + 7], 16, -155497632)
      b = hh(b, c, d, a, x[i + 10], 23, -1094730640)
      a = hh(a, b, c, d, x[i + 13], 4, 681279174)
      d = hh(d, a, b, c, x[i + 0], 11, -358537222)
      c = hh(c, d, a, b, x[i + 3], 16, -722521979)
      b = hh(b, c, d, a, x[i + 6], 23, 76029189)
      a = hh(a, b, c, d, x[i + 9], 4, -640364487)
      d = hh(d, a, b, c, x[i + 12], 11, -421815835)
      c = hh(c, d, a, b, x[i + 15], 16, 530742520)
      b = hh(b, c, d, a, x[i + 2], 23, -995338651)
      a = ii(a, b, c, d, x[i + 0], 6, -198630844)
      d = ii(d, a, b, c, x[i + 7], 10, 1126891415)
      c = ii(c, d, a, b, x[i + 14], 15, -1416354905)
      b = ii(b, c, d, a, x[i + 5], 21, -57434055)
      a = ii(a, b, c, d, x[i + 12], 6, 1700485571)
      d = ii(d, a, b, c, x[i + 3], 10, -1894986606)
      c = ii(c, d, a, b, x[i + 10], 15, -1051523)
      b = ii(b, c, d, a, x[i + 1], 21, -2054922799)
      a = ii(a, b, c, d, x[i + 8], 6, 1873313359)
      d = ii(d, a, b, c, x[i + 15], 10, -30611744)
      c = ii(c, d, a, b, x[i + 6], 15, -1560198380)
      b = ii(b, c, d, a, x[i + 13], 21, 1309151649)
      a = ii(a, b, c, d, x[i + 4], 6, -145523070)
      d = ii(d, a, b, c, x[i + 11], 10, -1120210379)
      c = ii(c, d, a, b, x[i + 2], 15, 718787259)
      b = ii(b, c, d, a, x[i + 9], 21, -343485551)
      a = ad(a, olda)
      b = ad(b, oldb)
      c = ad(c, oldc)
      d = ad(d, oldd)
    }
    return rh(a) + rh(b) + rh(c) + rh(d)
  }

  const handleAccessChangeForEmail = (item, user) => {
    console.log(item, user)
    if (studyOrFolder.type == "folder") {
      fetchData("PUT", "/folder/share/changeAccess", {
        access: item.value,
        folderId: studyOrFolder.id,
        viewerId: user.subscribersId
      })
        .then((res) => {
          toast.success("Access updated")
        })
        .catch((err) => {
          console.log(err)
        })
    } else
      fetchData("PUT", "/study/share", {
        accessLevel: item.value,
        studyCode: studyOrFolder?.code,
        subscriberUser: user.id,
        shareByEmail: true
      })
        .then(() => {
          toast.success("Access updated")
        })
        .catch((err) => {
          console.log(err)
        })
  }

  return (
    <>
      <Collapse title={"Share with link"}>
        <AnyoneWithTheLink studyOrFolder={studyOrFolder} />
      </Collapse>

      <OnlyPeopleWithEmailAccess
        getFoldersubscribers={getFoldersubscribers}
        getSubscribers={getSubscribers}
        studyOrFolder={studyOrFolder}
        setStudy={setStudy}
        modalId={modalId}
      />

      <div>
        <h4 className='text-dicom-headline4'>People with access:</h4>
        <div id='people-with-access' className='min-h-[14rem]  max-h-56 overflow-auto'>
          {peopleWithAccessList
            ? peopleWithAccessList.map((item, index) => (
                <div key={index} className={"flex gap-x-dicom-10 items-center text-sm py-[5px] px-[10px] rounded-full"}>
                  <div>
                    <img
                      src={`https://www.gravatar.com/avatar/${computeHash(item?.email)}?s=200&d=mp`}
                      className='rounded-full !h-[34px] !w-[34px]'
                      alt=''
                    />
                  </div>
                  <div>
                    {item?.firstName ? item?.firstName : "(no name)"} {item?.lastName} {item?.isOwner ? "(You)" : ""}
                    <br></br>
                    <small className='text-dicom-caption-100'>{item?.email}</small>
                  </div>

                  {item?.isOwner ? (
                    <div className='bg-dicom-background-100 ml-auto px-2'>
                      <small>Owner</small>
                    </div>
                  ) : (
                    <div className='ml-auto'>
                      <AccessDropdown
                        initialAccessLevel={item.access}
                        handleAccessChange={handleAccessChangeForEmail}
                        innerClassName='right-2'
                        removeAccess={true}
                        handleDeleteAccess={removeEmailAccess}
                        user={item}
                      />
                    </div>
                  )}
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  )
}

export default ShareStudy
const Collapse = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className=''>
      <div className='flex justify-between items-center py-2 cursor-pointer' onClick={toggleCollapse}>
        <h2 className='text-sm font-semibold'>{title}</h2>
        <svg
          className={`w-4 h-4 transition-transform ease-in ${isOpen ? "rotate-0" : "rotate-180"}`}
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d={"M5 15l7-7 7 7"} />
        </svg>
      </div>
      {isOpen && <div className='collapse-contents'>{children}</div>}
    </div>
  )
}
