import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DicomButtonOutline } from "../../../../components/DesignSystem/buttons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../services/fetching";
import { IconBlackCopy } from "../../../../components/DesignSystem/svgIcons";
import { AccessDropdown, CheckMark, Dropdown, SVGICon } from "./Assets";
import { GetLocalStorage } from "../../../../services/LocalStorage";

const AnyoneWithTheLink = ({ studyOrFolder }) => {

    
  const [shareLink, setShareLink] = useState("");
  const [accessLevel, setAccessLevel] = useState(null);
  const projectBaseUrl =useRef("")

  const getSharedLink = () => {
    let url=`/study/share/shareLink?studyCode=${studyOrFolder.code}`
    if(studyOrFolder?.type=="folder"){
        url=`/folder/share/share-link?folderId=${studyOrFolder.id}`
    }

    fetchData("GET", url)
      .then((res) => {
 
        let link = projectBaseUrl.current +"shareId=" + res.data.data.linkId;

        setShareLink(link);
        setAccessLevel(res.data.data.accessLevel);
        console.log(link,"sharelink")
      })
      .catch((err) => {
        toast.error("Problem getting share link");
      });
  };

  useEffect(() => {
    if (
      (studyOrFolder?.code !== undefined || studyOrFolder?.type=="folder") &&
      GetLocalStorage("login_key") !== null &&
      GetLocalStorage("login_user").id === studyOrFolder.userId 
    ){
       projectBaseUrl.current =
      studyOrFolder?.type=="folder"?
     `${ window.location.protocol }//${window.location.host }/dashboard/shared/folder/${studyOrFolder?.id}?p_id=${studyOrFolder?.id}&`
      :
       `${window.location.protocol}//${window.location.host}/dashboard/my-dicom-wallet/${studyOrFolder?.code}?`
     
      getSharedLink();
    }
  }, [studyOrFolder]);

  const handleChangeLink = () => {
    toast((t) => (
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 inline-block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>
        This will reset your previously generated link.
        <div className="flex gap-4 mt-3">
          <button
            className="btn btn-xs btn-outline hover:bg-red-500 hover:border-red-500 hover:text-white border-red-500 text-red-500"
            onClick={() => toast.dismiss(t.id)}
          >
            Dismiss
          </button>
          <button
            className="btn btn-xs btn-outline hover:bg-green-500 hover:border-green-500 hover:text-white border-green-500 text-green-500"
            onClick={() => {
              changleLink();
              toast.dismiss(t.id);
            }}
          >
            Proceed
          </button>
        </div>
      </span>
    ));
  };

  const handleCopy = ()=>{
    console.log("clicked")
      toast.success("Copied to clipboard")
  }

  const changleLink = () => {
    let url =`/study/share/changeShareLink?studyCode=${studyOrFolder?.code}`
    if(studyOrFolder?.type=="folder"){
      url= `/folder/share/changeShareLink?folderId=${studyOrFolder?.id}`
    }
    fetchData("PUT",url )
      .then((res) => {
        let link = projectBaseUrl.current + "shareId=" + res.data.data.linkId;
        setShareLink(link);
        toast.success("Share link changed");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Problem changing the share link");
      });
  };
  return (
    <>
      <div className="flex items-center gap-2">
        <div className="flex-1 input input-md border-2 border-dicom-secondary-100 w-full relative flex items-center">
          <input
            type="text"
            className="h-full w-[95%] focus:outline-none"
            placeholder="Link"
            defaultValue={shareLink}
            readOnly
          />
          <div className="tooltip cursor-pointer" data-tip="Copy Link">
          <CopyToClipboard text={shareLink} onCopy={()=>handleCopy()}>
            <div>
            <IconBlackCopy color="#1DD3B0" />
               </div>
          </CopyToClipboard>
          </div>
          
        </div>
        <div className="flex items-center flex-initial gap-2">
          <AceessLevel accessLevel={accessLevel} studyofFolder={studyOrFolder}/>
          <span className="text-xs btn-link text-dicom-secondary-100 cursor-pointer flex-1" onClick={handleChangeLink}>Changle link</span>
        </div>
      </div>
    </>
  );
};

export default AnyoneWithTheLink;

const AceessLevel = ({  accessLevel, studyofFolder }) => {
  const handleAccessChange = (meta) => {
    let url ="/study/share"
    let data= {
      accessLevel: meta.value,
      studyCode: studyofFolder.code,
    }
    if(studyofFolder?.type=="folder"){
      url="/folder/share/changeShareLinkAccess"
      data={
        accessLevel:meta.value,
        folderId:studyofFolder.id
      }
    }
    fetchData("PUT", url, data).then((res) => {
      toast.success("Access updated");
    });
  };

  return (
    <div className="flex items-center flex-initial">   
        <AccessDropdown
          handleAccessChange={handleAccessChange}
          initialAccessLevel={accessLevel}
          className={"max-w-[320px]"}
          innerClassName={"max-w-[192px] md:max-w-[320px]"}
        />
      </div>
  );
};
