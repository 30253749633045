import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FolderCard from "../../../components/dashboard/folderCard";
import {
  DicomButton,
  DicomButtonOutlineLabel,
} from "../../../components/DesignSystem/buttons";
import Modal from "../../../components/DesignSystem/Modal";
import { fetchData } from "../../../services/fetching";
import NewFolder from "./newFolder";

const AddToFolder = ({ selectedData }) => {
  const [foldersData, setFoldersData] = useState([]);
  const [folderSelected, setFolderSelected] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchData("GET", "/folder").then((res) => {
      if (res?.data?.data?.folders?.length) {
        res.data.data.folders = [
          ...res.data.data.folders,
          {
            id: 0,
            name: "No folder",
            studiesCount: null,
          },
        ];
      }
      setFoldersData(res.data.data.folders);
    });
  }, []);

  let StudisaddToFolder = [];
  // let foldersId = null;
  for (let key in selectedData) {
    StudisaddToFolder.push(selectedData[key]?.code);
    // foldersId = selectedData[key]?.folderId;
  }

  const AddFolderHandler = () => {
    fetchData("POST", "/folder/file/move", {
      studies: StudisaddToFolder,
      from: folderSelected,
      to: folderSelected,
    })
      .then((res) => {
        if (res.data.data.files) {
          navigate("/dashboard/folders");
          toast.success("Study folder changed successfully");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Modal modalId={"add-folder-modal"} title={"Create New Folder"}>
        <NewFolder
          setFoldersData={setFoldersData}
          modalId={"add-folder-modal"}
        />
      </Modal>
      {foldersData.length ? (
        <>
          <div className={"grid grid-cols-6 lg:grid-cols-8 gap-dicom-10"}>
            {foldersData.map((item, key) => (
              <FolderCard
                folderSelected={folderSelected}
                onChange={(e) => setFolderSelected(parseInt(e.target.value))}
                className={"col-span-2 lg:col-span-1"}
                key={key}
                folder={item}
              />
            ))}
          </div>
          <div className="flex items-center justify-center">
            <DicomButton
              title={"Done"}
              btnClass={"w-full lg:!w-fit"}
              onClick={AddFolderHandler}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center space-y-dicom-15 py-[87px] px-[68px]">
          <img src="/images/Folders.svg" className="w-[152px]" alt="" />
          <p className="text-dicom-body1 text-dicom-secondary-100">
            You have not added any folder yet
          </p>
          <DicomButtonOutlineLabel
            htmlFor={"add-folder-modal"}
            btnClass={
              "!px-[10px] !border-dicom-secondary-100 !text-dicom-secondary-100 hover:!bg-dicom-secondary-100/5"
            }
            title={"Add New Folder"}
            titleAttr={"Create folder"}
            iconL={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17Z"
                  fill="#1DD3B0"
                />
              </svg>
            }
          />
        </div>
      )}
    </>
  );
};

export default AddToFolder;
